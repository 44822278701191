import * as React from "react";
import {PageLayout, LoadingSpinner, MarkdownPageLayout} from "../components/components";
import {Button} from "react-bootstrap";
import useTeamInActiveGameRedirect from "../hooks/useTeamInActiveGameRedirect";
import appContent from "../markdown/app-content";
import ApplyCustomGameTheme from "../app/game/shared/apply-custom-game-theme/ApplyCustomGameTheme";
import {useContext} from "react";
import GameContext from "../context/game-context";
import useFirebaseHasActiveGames from "../hooks/useFirebaseHasActiveGames";
import {GameOptions} from "../entities/game";

const ActiveGame = ({game}) => {
  let buttonLink = "game/join-game";
  if (game) {
    const params = new URLSearchParams({gameId: game?.id});
    buttonLink += `?${params.toString()}`
  }

  const markdown = game?.getOption(GameOptions.OVERRIDE_INTRO_TEXT) || appContent.home.activeGameMarkdown;

  return <MarkdownPageLayout markdown={markdown} logo="lg" usePrimaryGame>
    <Button
      href={buttonLink}
      block
    >
      {appContent.home.startButton}
    </Button>
  </MarkdownPageLayout>;
};

const IndexPage = () => {
  const {gameOnHomePage, isLoadingGameOnHomePage} = useContext(GameContext);
  const {isLoading: isLoadingTeamRedirect } = useTeamInActiveGameRedirect(`/game/in-game`);
  const {hasActiveGames, isLoading: isLoadingHasActiveGames} = useFirebaseHasActiveGames();
  const isLoading = isLoadingGameOnHomePage || isLoadingTeamRedirect || isLoadingHasActiveGames;

  return <>
    {isLoading && (
      <PageLayout>
        <LoadingSpinner isLoading={isLoading}/>
      </PageLayout>
    )}
    {!isLoading && hasActiveGames && (
      <ActiveGame game={gameOnHomePage}/>
    )}
    {!isLoading && !hasActiveGames && (
      <MarkdownPageLayout
        logo="sm"
        markdown={appContent.home.noActiveGameMarkdown}
      />
    )}
    <ApplyCustomGameTheme usePrimaryGame />
  </>;
};

export default IndexPage;
