import { useEffect, useContext } from "react";
import { navigate } from "gatsby";
import GameContext from "../context/game-context";
import {GameOptions} from "../entities/game";

export default function useTeamInActiveGameRedirect(redirectUrl) {
  const { game, team, isLoading } = useContext(GameContext);

  useEffect(() => {
    if (!game) {
      return;
    }

    if (!isLoading && team && game.getOption(GameOptions.IS_ACTIVE)) {
      navigate(redirectUrl);
    }
  });

  return {
    isLoading
  };
}
